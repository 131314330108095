define([
    'Backbone',
    'dict/isocountries.json',
    'dict/forbidden-countries.json',
    'templates/register/advertizer.html',
    'templates/register/countryInputRow.html',
], function (
    Backbone,
    countryData,
    forbiddenCountries,
    template,
    countryInputRowTemplate,
) {

    return Backbone.View.extend({

        events: {
            'click .view-close': 'destroy',
            'focusin .input-wrapper input': 'onInputFocusIn',
            'focusout .input-wrapper input': 'onInputFocusOut',
            'click .signup-select': 'showHideSelect',
            'click .registerModal .panel': 'hideSelect',
            'change .country-select input': 'onCountryChanged',
            'click .registerBtn': 'register',
            'change #screenshot': 'screenshotChanged'
        },

        initialize: function (options) {
            options || (options = {});
            this.helpText = options.helpText || 'Complete the form below to sign up.';
            this.successHelpContent = options.successHelpContent || '';
            this.template = _.template(template);
            this.apiUrl = API_URL + 'data/advertizersite-register.php';
            this.countryInputRowTemplate = _.template(countryInputRowTemplate);
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.append(this.template({
                helpText: this.helpText,
                successHelpContent: this.successHelpContent
            }));
            var $container = this.$el.find('.countryInputOptions');
            _.each(countryData, function (country) {
                if (!forbiddenCountries.includes(country.alpha_2_code)) {
                    $container.append(this.countryInputRowTemplate({
                        value: country.alpha_2_code,
                        text: country.country
                    }));
                }
            }, this);
            $("#appContainer").append(this.$el);
            this.$el.find('.overlay').fadeIn('fast');
        },

        destroy: function() {
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        onInputFocusIn: function(ev) {
            $(ev.currentTarget).closest('.input-wrapper').addClass('focus');
        },

        onInputFocusOut: function(ev) {
            $(ev.currentTarget).closest('.input-wrapper').removeClass('focus');
        },

        onCountryChanged: function(ev) {
            var $target = $(ev.target);
            var $select = $target.closest('.country-select');
            var country = $target.data('country');
            $select.removeClass('show');
            if ($target.val()) {
                $select.find('.selectedValue').text(country);
            }
        },

        hideSelect: function(ev) {
            this.$el.find('.signup-select').removeClass('show');
        },

        showHideSelect: function(ev) {
            $(ev.currentTarget).toggleClass('show');
            ev.stopPropagation();
        },

        screenshotChanged: function(ev) {
            var file = ev.currentTarget.files[0];
            if (!file) {
                return;
            }
            var $input = this.$el.find('#screenshot');
            var $label = this.$el.find('#screenshot-label');
            var $error = this.$el.find('.formError');
            var fileName = file.name;
            var filesize = ((file.size/1024)/1024).toFixed(4); //MB

            if(filesize > 2.0) {
                $error.text("Screenshot file size should not exceed 2.0MB")
                $input.val('');
            } else {
                $error.text("").hide();
                $label.text(fileName);
            }
        },

        register: function(ev) {
            var self = this;
            var name = this.$el.find('.signup-input.name').val(),
                email = this.$el.find('.signup-input.email').val(),
                skype = this.$el.find('.signup-input.skype').val(),
                reg_username = this.$el.find('.signup-input.reg_username').val(),
                country_code = this.$el.find('.countryInput:checked').val() || '',
                moreInfo = this.$el.find('.signup-input.more_info').val(),
                $error = this.$el.find('.formError'),
                $btn = $(ev.currentTarget),
                pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

            $error.hide();

            if(name === '' || email === '' || skype === '' || reg_username === '' || moreInfo === '' || $('.typeInput:checked').length === 0 || $('.conversionInput:checked').length === 0) {
                $error.text('Please complete the form').show();
            } else if (!pattern.test(email)) {
                $error.text('Please enter a valid email address').show();
            } else {
                $error.hide();

                var formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('skype', skype);
                formData.append('reg_username', reg_username);
                formData.append('country_code', country_code);
                formData.append('offer_niche', '');
                formData.append('utm_campaign', '');

                var screenshotFile = this.$el.find('#screenshot')[0].files[0];
                if (screenshotFile) {
                    formData.append('screenshot', screenshotFile);
                }

                var offer_niche = '';
                this.$el.find('.typeInput:checked').each(function() {
                    offer_niche += $(this).val()+', ';
                });
                this.$el.find('.conversionInput:checked').each(function() {
                    offer_niche += $(this).val()+', ';
                });
                offer_niche += $('.more_info').val();
                formData.append('offer_niche', offer_niche);

                formData.append('action', 'submit');

                $error.hide();
                $btn.prop('disabled', true);

                // do the ajax
                $.ajax({
                    type: 'POST',
                    url: this.apiUrl,
                    data: formData,
                    dataType: 'json',
                    contentType: false,
                    processData: false,
                    cache: false,
                    success: function(data) {
                        $btn.prop('disabled', false);
                        if(typeof data.error !== 'undefined' && data.error) {
                            $error.text(data.message).show();
                        } else {
                            self.$el.find('.signup-form').fadeOut('fast');
                            self.$el.find('.successMsg').fadeIn('fast');
                        }
                    }
                });
            }
        },

    });
});
